import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import { Today } from "@mui/icons-material";
import logo from "../assets/logo.jpeg";
import { ListItemText, Slide, useScrollTrigger } from "@mui/material";

const pages = [
  // { label: "Home", to: "/" },
  { label: "Health Club", to: "/health-club" },
  { label: "Trainers", to: "/our-trainers" },
  { label: "Memberships", to: "/membership-plans" },
  { label: "Contact", to: "/contact" },
  {
    label: "Class Schedule",
    to: "https://www.signupgenius.com/go/30e0d49ada82dabfe3-cscgroup#/",
  },
  {
    label: "Child Care",
    to: "https://www.signupgenius.com/go/30e0d49ada82dabfe3-csckids#/",
  },
];
const settings = [
  {
    label: "Class Schedule",
    url: "https://www.signupgenius.com/go/30e0d49ada82dabfe3-cscgroup#/",
  },
  {
    label: "Child Care",
    url: "https://www.signupgenius.com/go/30e0d49ada82dabfe3-csckids#/",
  },
];

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    window.scrollTo(0, 0);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    window.scrollTo(0, 0);
    setAnchorElUser(null);
  };

  return (
    <HideOnScroll>
      <AppBar
        position="fixed"
        style={{ zIndex: 1000, backgroundColor: "white" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={NavLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                onClick={() => window.scrollTo(0, 0)}
                src={logo}
                alt=""
                style={{ width: "150px" }}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon style={{fontSize: '1.6rem'}} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.to} onClick={handleCloseNavMenu}>
                    <ListItemText>
                      <Typography
                        component={NavLink}
                        to={page.to}
                        textAlign="center"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {page.label}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component={NavLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "black",
                textDecoration: "none",
              }}
            >
              <img
                onClick={() => window.scrollTo(0, 0)}
                src={logo}
                alt=""
                style={{ width: "150px" }}
              />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.to}
                  component={NavLink}
                  to={page.to}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="classes & child care">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Today style={{ color: "#de6f23", fontSize: "1.5rem" }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu(settings[0])}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.label}
                    onClick={() => {
                      window.open(setting.url, "_blank");
                      handleCloseUserMenu(setting.label);
                    }}
                  >
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}
export default NavBar;
