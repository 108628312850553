import React from "react";

import { FaFacebook, FaLinkedin, FaPhone } from "react-icons/fa";
import "./footer.css";
import { AccessTime, CopyrightOutlined, LocationOn } from "@mui/icons-material";
import { IconContext } from "react-icons";

const Footer = () => {
  const linkStyle = {
    color: "white",
    textDecoration: "none",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "10px",
    height: "20px",
    width: "20px",
    fill: "grey",
  };

  const address = "4250 North High Street Columbus, OH 43214";

  const openMaps = () => {
    // Constructing the Google Maps link with the address
    const mapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    // Open the link in a new tab
    window.open(mapsLink, "_blank");
  };

  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          {/* <img src={logo} alt="" /> */}
        </div>
        <div className="footer-icons">
          <IconContext.Provider
            value={{
              size: "2em",
              color: "#b0b0b0",
              style: { margin: "0.5rem" },
            }}
          >
            {/* Social media icons with links */}
            <a
              href="https://www.facebook.com/profile.php?id=100057378021597"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a href="tel:6142672582">
              <FaPhone />
            </a>

            <a
              href="https://www.linkedin.com/company/columbus-sports-connection/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            {/* Add more social media icons as needed */}
          </IconContext.Provider>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span style={{ display: "block", marginBottom: "20px" }}>
            <a style={linkStyle} href="mailto:">
              <AccessTime style={iconStyle} /> M-F 5:30am-9pm | Sat-Sun 8am-5pm
            </a>
          </span>
          <span style={{ display: "block", marginBottom: "20px" }}>
            <p style={linkStyle} onClick={openMaps}>
              <LocationOn style={iconStyle} /> 4250 North High Street Columbus,
              OH 43214
            </p>
          </span>
        </div>
        <div className="footer-section-columns">
          <h2 style={{ color: "grey" }}>A Total Fitness Facility</h2>
        </div>
        <div className="footer-section-columns">
          <span className="footer-link">
            <p style={{ color: "#" }}>
              {" "}
              <span style={{ color: "grey" }}>
                <CopyrightOutlined style={iconStyle} /> 2024 CSC
              </span>{" "}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
