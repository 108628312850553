import React from 'react'
import Plans from '../Components/Plans/Plans'
import { Celebration, FitnessCenter, StickyNote2 } from '@mui/icons-material'

const Membership = () => {
  return (
    <div>
      <Plans />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "8rem",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <StickyNote2 style={{ fontSize: 50, color: "#de6f23" }} />
          <p className="primary-text">Sign Up</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <FitnessCenter style={{ fontSize: 50, color: "#313131" }} />
          <p className="primary-text">Get Active</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Celebration style={{ fontSize: 50, color: "#de6f23" }} />
          <p className="primary-text">Reach goals </p>
        </div>
      </div>
    </div>
  )
}

export default Membership
