import React from 'react'

import Trainers from '../Components/Trainers/Trainers'
import TrainerQ from '../Components/Questions/TrainerQ'
const TrainersPage = () => {
  return (
    <div style={{marginTop: '3rem'}}>
      <Trainers />
      <TrainerQ />
    </div>
  )
}

export default TrainersPage
