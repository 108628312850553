import React from "react";
import Header from "./Header";
import WhatWeOffer from "../Offers/WhatWeOffer";
import Plans from "../Plans/Plans";
import Trainers from "../Trainers/Trainers";
import TrainerQ from "../Questions/TrainerQ";
import ContactForm from '../Contact/ContactForm';

const Home = () => {
  return (
    <div>
      <Header />
      <WhatWeOffer />
      <Plans />
      <Trainers />

      <TrainerQ />
      <ContactForm />
    </div>
  );
};

export default Home;
