import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Instagram, Email, Phone } from "@mui/icons-material";
import todd from "../../assets/todd.jpeg";
import heather from "../../assets/heather.jpeg";
import pete from "../../assets/pete.jpg";
import trav from "../../assets/trav.jpeg";
import gina from "../../assets/gina.jpeg";
import { ArrowRight } from "@mui/icons-material";

const Cards = () => {
  const goToTodd = () => {
    const toddLink = "https://precisionwellnesstraining.com/";
    window.open(toddLink, "_blank");
  };

  const goToHeather = () => {
    const heatherLink = "https://friestraining.com/";
    window.open(heatherLink, "_blank");
  };

  const goToTrav = () => {
    const travLink = "https://www.tdwellnesscoaching.com/";
    window.open(travLink, "_blank");
  };

  const callPeter = () => {
    const phoneNumber = "6147462577";
    const phoneLink = `tel:${phoneNumber}`;
    window.open(phoneLink);
  };

  const callGina = () => {
    const phoneNumber = "6142183238";
    const phoneLink = `tel:${phoneNumber}`;
    window.open(phoneLink);
  };

  const sendEmailToGina = () => {
    const emailAddress = "gmfitco@gmail.com";
    const emailLink = `mailto:${emailAddress}`;
    window.open(emailLink, "_blank");
  };

  const sendEmailToPeter = () => {
    const emailAddress = "peterjmccann64@gmail.com";
    const emailLink = `mailto:${emailAddress}`;
    window.open(emailLink);
  };

  const goToPeterInstagram = () => {
    const peterInstagramLink = "https://www.instagram.com/mccann5x5/";
    window.open(peterInstagramLink, "_blank");
  };

  const goToGinaInstagram = () => {
    const ginaInstagramLink = "https://www.instagram.com/gm_fit_co/";
    window.open(ginaInstagramLink, "_blank");
  };

  const cardData = [
    {
      title: "Todd Zody",
      description:
        "Todd is a licensed Occupational Therapist, ACSM-certified exercise physiologist, and Functional Aging Specialist who owns Precision Wellness Personal Training. He creates personalized workout plans to improve the strength, stamina, balance, and overall well-being of those aged 55 and over, taking into account physical concerns and health conditions such as osteoporosis, diabetes, and arthritis.",
      image: todd,
    },
    {
      title: "Heather Fries",
      description:
        "Heather is a NASM certified trainer who is focused on creating safe & effective programs to help achieve your fitness goals - weight loss, improved strength or enhanced mobility. Her approach is fitness that applies to your daily life - a life that requires a strong foundation of core strength & coordinated movement. Don’t let your body become a limiting factor in your participation!",
      image: heather,
    },
    {
      title: "Travis Davis",
      description:
        'Travis is a certified NASM-CPT and CNC trainer with a background in competitive powerlifting, football, track, and tactical sports conditioning. He uses the TD wellness model, which employs a "C.O.M.P.A.S.S" approach to help individuals gain vitality and improve their health and longevity. Travis offers personalized and adaptable programs for high school athletes, rehabilitating adults, and anyone in between.',
      image: trav,
    },
    {
      title: "Peter McCann",
      description:
        "Peter believes that physical fitness can unlock dormant abilities and improve every aspect of life. For a decade, he has coached athletes to improve their strength, power, and speed. Peter has helped people of all ages discover their athleticism through better movement patterns and resistance training. As a CSC trainer, he feels privileged to serve the community and help individuals realize their potential.",
      image: pete,
    },
    {
      title: "Gina Moser",
      description:
        "Gina is a NASM certified personal trainer who specializes in strength training, total body conditioning and weight loss. She loves pushing clients just beyond their limits. As your personal trainer, she will ensure that you not only achieve your goals but develop a solid foundation. Her mission is to be the last trainer you'll ever need, guiding you with precision and expertise to optimize your workouts.",
      image: gina,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        overflowX: "auto",
        padding: ".6rem",
        marginTop: "2rem",
      }}
    >
      {cardData.map((card, index) => (
        <Card
          key={index}
          className="cardStyle"
          style={{
            flex: "0 0 auto",
            marginLeft: ".1rem",
            width: "350px",
            height: "500px",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "200px",
              background: `url(${card.image}) center/cover no-repeat`,
            }}
          />
          <CardContent style={{ padding: "1rem" }}>
            <Typography variant="h6" component="div">
              {card.title}
            </Typography>
            <Typography
              style={{
                color: "white",
                lineHeight: "1.5",
              }}
              variant="body2"
              color="text.secondary"
            >
              {card.description}
            </Typography>
          </CardContent>
          <CardActions>
            {index <= 2 && (
              <Button
                size="small"
                endIcon={<ArrowRight />}
                style={{ color: "#de6f23" }}
                onClick={() => {
                  switch (index) {
                    case 0:
                      goToTodd();
                      break;
                    case 1:
                      goToHeather();
                      break;
                    case 2:
                      goToTrav();
                      break;
                    default:
                      break;
                  }
                }}
              >
                See more
              </Button>
            )}
            {(index === 3 || index === 4) && (
              <>
                <Button
                  size="small"
                  endIcon={<Instagram />}
                  style={{ color: "#de6f23" }}
                  onClick={() => {
                    switch (index) {
                      case 3:
                        goToPeterInstagram();
                        break;
                      case 4:
                        goToGinaInstagram();
                        break;
                      default:
                        break;
                    }
                  }}
                />
                <Button
                  size="small"
                  endIcon={<Email />}
                  style={{ color: "#de6f23" }}
                  onClick={() => {
                    switch (index) {
                      case 3:
                        sendEmailToPeter();
                        break;
                      case 4:
                        sendEmailToGina();
                        break;
                      default:
                        break;
                    }
                  }}
                />
                <Button
                  size="small"
                  endIcon={<Phone />}
                  style={{ color: "#de6f23" }}
                  onClick={() => {
                    switch (index) {
                      case 3:
                        callPeter();
                        break;
                      case 4:
                        callGina();
                        break;
                      // Add more cases if needed
                      default:
                        break;
                    }
                  }}
                />
              </>
            )}
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

export default Cards;
