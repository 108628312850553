import React from "react";
import gymRacks from "../assets/gymracks.jpeg";
import view1 from "../assets/view1.jpeg";
import weights from "../assets/personalt.jpeg";
import { Button, Divider } from "@mui/material";
import Classes from "../Components/Classes/Classes";
import TrainerCards from "../Components/Trainers/TrainerCards";
import michelle from "../assets/michele.jpeg";

const HealthClubPage = () => {
  const bookAppt = () => {
    const url = "https://www.michellerobisonlmt.com/services";

    window.open(url, "_blank");
  };

  return (
    <div style={{ marginTop: "5rem", textAlign: "center" }}>
      <img
        src={gymRacks}
        alt="Gym Racks"
        style={{
          width: "330px",
          height: "405px",
          objectFit: "cover",
          marginRight: "10px",
        }}
      />
      <img
        src={view1}
        alt="View 1"
        style={{
          width: "330px",
          maxHeight: "400px",
          objectFit: "cover",
          margin: "0 10px",
        }}
      />
      <img
        src={weights}
        alt="Weights"
        style={{
          width: "330px",
          maxHeight: "400px",
          objectFit: "cover",
          marginLeft: "10px",
        }}
      />

      <Divider>
        <h3
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            letterSpacing: "2px",
          }}
        >
          CLASSES
        </h3>
      </Divider>
      <Classes />
      <div style={{ marginTop: "2rem" }}>
        <Divider>
          <h3
            style={{
              textAlign: "center",
              fontSize: "1.8rem",
              letterSpacing: "2px",
            }}
          >
            PERSONAL TRAINING
          </h3>
        </Divider>
        <p>
          We have a divese group of trainers ready to help you meet your goals!
        </p>
        <TrainerCards />
      </div>
      <div style={{ marginTop: "2rem" }}>
        <Divider>
          <h3
            style={{
              textAlign: "center",
              fontSize: "1.8rem",
              letterSpacing: "2px",
            }}
          >
            MASSAGE THERAPY
          </h3>
        </Divider>
      </div>
      <h2>Meet Michelle Robison</h2>
      <img src={michelle} alt="" style={{ width: "300px", height: "260px" }} />
      <p style={{ textAlign: "center", padding: "10px" }}>
        Licensed massage therapist specializes in enhancing clients' well-being
        through massage programs. Based in Columbus, Ohio, she graduated from
        the American Institute of Alternative Medicine.
      </p>
      <div style={{marginTop: '1rem', padding: '10px'}}>
      <Button
        variant="contained"
        style={{ marginTop: ".5rem", backgroundColor: "black" }}
        onClick={bookAppt}
      >
        Book appointment
      </Button>
      </div>

    </div>
  );
};

export default HealthClubPage;
