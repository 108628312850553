import React from "react";
import Column from "../Column/Column";

const WhatWeOffer = () => {
  return (
    <div id="health-club-section" style={{background: 'rgba(200, 200, 200, 0.5)', 
}}>
      <h3
        style={{
          textAlign: "center",
          fontSize: "1.8rem",
          letterSpacing: "2px",
          marginTop: "37rem",
          padding: '5px'

        }}
      >
        What We Offer{" "}
       
      </h3>
      <p style={{ marginTop: "2.5rem", textAlign: 'center' }}>
          
        </p>
        <Column />
    </div>
  );
};

export default WhatWeOffer;
