
import React from "react";
import TrainerCards from "./TrainerCards";

const Trainers = () => {
  return (
    <div
      style={{
        background:
          "rgba(200, 200, 200, 0.5)", 
          padding: '2px'
      }}
    >
      <h3
        style={{
          textAlign: "center",
          fontSize: "1.8rem",
          letterSpacing: "2px",
          marginTop: "2rem",
        }}
      >
        Meet Our Trainers
      </h3>

      <h2 style={{ marginLeft: "2rem", letterSpacing: "2px" }}>
        Let's meet your{" "}
        <span
          style={{
            background: "linear-gradient(90deg, #de6f23, #de6f23)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: "bold",
          }}
        >
          {" "}
          fitness goals.
        </span>
      </h2>
      <TrainerCards />
    </div>
  );
};

export default Trainers;
