import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import ContactForm from "./Components/Contact/ContactForm";

import NavBar from "./Components/Navbar";
import HealthClubPage from "./Pages/HealthClubPage";
import Membership from "./Pages/Membership";
import TrainersPage from "./Pages/TrainersPage";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="health-club" element={<HealthClubPage />} />
        <Route path="our-trainers" element={<TrainersPage />} />
        <Route path="membership-plans" element={<Membership />} />
        <Route path="contact" element={<ContactForm />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
