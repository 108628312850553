import { Button } from "@mui/material";
import React from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const nav = useNavigate();

  return (
    <div className="header-overlay">
      <div>
        <h3 style={{ color: "#de6f23" }}>
          Columbus{" "}
          <span
            style={{
              backgroundImage: "linear-gradient(to right, #de6f23, #de6f23)",
              WebkitBackgroundClip: "text",
              display: "inline-block",
              fontFamily: "inherit",
              textDecoration: "none",
            }}
          >
            Sports Connection
          </span>
          <br />{" "}
          <span
            style={{
              // background: "linear-gradient(to right, #dcdcdc, #f0f0f0)",
              padding: "1px",
              color: "#A9A9A9",
              WebkitBackgroundClip: "text",
              display: "inline-block",
              textDecoration: "none",
              fontSize: "1.8rem",
              letterSpacing: "2px",
              fontWeight: "600",
            }}
          >
            A Total Fitness Facility
          </span>
        </h3>
        <p style={{ marginTop: "2rem", fontSize: "1.1rem" }}>
          Locally owned and operated in the heart of Clintonville since 1989,
          Columbus Sports Connection provides a supportive environment with
          quality equipment and spacious workout areas to optimize your fitness
          experience. Visit us today and experience for yourself why Columbus
          Sports Connection has been the premier gym in town for over 30 years.
        </p>
        <div style={{ marginTop: ".5rem" }}>
          {/* <Button
          variant="outlined"
          style={{
            backgroundColor: "white",
            borderColor: "grey",
            color: "black",
            marginRight: ".5rem",
          }}
          onClick={handleContactClick}
        >
          Contact
        </Button> */}
          <Button
            onClick={() => nav("contact")}
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "black",
              borderColor: "grey",
              marginRight: ".5rem",
            }}
          >
            Contact
          </Button>
          <Button
            onClick={() => nav("health-club")}
            variant="contained"
            style={{
              backgroundColor: "#313131",
              color: "white",
              borderColor: "grey",
            }}
          >
            Explore
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
